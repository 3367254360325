import React, { useEffect } from "react";
import loadable from "@loadable/component";
import { Link } from "gatsby";
import { useLocale, useDictionaryQuery, useSearchQuery } from "../../../hooks";
import ProductDescription from "../ProductDescription/ProductDescription";
import IngredientsDescription from "../IngridentsDescription/IngridentsDescription";
import ProductReviews from "../Reviews/ProductReviews";
import * as styles from "./product-form.module.scss";
import QuestionProduct from "../QuestionAndAnswer/QuesProduct";

const ProductSlider = loadable(() => import("../ProductSlider/ProductSlider"));

const ProductForm = ({
  title,
  slug,
  categoryTitle,
  subTitle,
  packageSize,
  productId,
  cta,
  relatedProducts,
  productDescription,
  ingredientsDescription,
  longSubtitle,
}) => {
  const lang = useLocale();
  const { questionandAnswer, ratingsandReview } = useSearchQuery();
  const { productsFullCharacteristics, productPageOtherProducts } = useDictionaryQuery();
  const { productDoNotDisplayButton } = useSearchQuery();

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `
      if (typeof PriceSpider !== 'undefined' && PriceSpider.rebind) {
        PriceSpider.rebind();
      }
    `;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);


  const renderCTAButton = () => {
    if (lang !== "en-US") {
      if (productDoNotDisplayButton) {
        return null;
      } else if (cta && cta[0] && cta[0].slug) {
        return (
          <Link target="_blank" to={`${cta[0].slug}`}>
            <button className="btn-primary">{cta && cta[0].title}</button>
          </Link>
        );
      } else if (cta) {
        return <button className="btn-primary">{cta && cta[0].title}</button>;
      }
    }
    return null;
  };

  return (
    <>
      <div className={`${styles.col_2} spacer__01`}>
        <p className={styles.categoryTitle}>{categoryTitle}</p>
        <h1 className={styles.h2}>{title}</h1>
        <p>{subTitle}</p>
        <p className={styles.subTitle}>{longSubtitle?.longSubtitle}</p>
        <p className={styles.package_size}>{packageSize}</p>
        {renderCTAButton()}
        {lang === "en-US" && !productDoNotDisplayButton && (
          <>
            <div className={`${styles.shop} ps-widget`} ps-sku={productId}></div>
            <div className={styles.space}>
              <div data-bv-show="rating_summary" data-bv-product-id={productId}></div>
            </div>
          </>
        )}
        {relatedProducts && (
          <ProductSlider productArray={relatedProducts} title={productPageOtherProducts} />
        )}
        <div className={styles.description}>
          <ProductDescription content={productDescription} title={productsFullCharacteristics} />
          {ingredientsDescription && (
            <IngredientsDescription content={ingredientsDescription} title="Ingredienser" />
          )}
          {ratingsandReview && (
            <ProductReviews title={ratingsandReview} productId={productId} />
          )}
          {questionandAnswer && (
            <QuestionProduct title={questionandAnswer} productId={productId} />
          )}
        </div>
      </div>
    </>
  );
};

export default ProductForm;
