import React, { useState } from "react";
import { RichText } from "../../Layout/RichText/RichText";

import * as styles from './Ingridents-description.module.scss'

const IngredientsDescription = ({
    content,
    title
}: {
    content: object,
    title: string
}) => {
    const [display, setDisplay] = useState(true)
    return (
        <div>
            <div className={`${styles.productDiscription} ${display && styles.productIcon}`}>
                <div className={`${styles.descriptionTitle}`} onClick={() => { setDisplay(!display) }}>
                    <a>{title}</a>
                    <p className={`${`styles.productPlus`}`}></p>
                </div>
            </div>
            {
                content &&
                <div className={`${styles.content} ${display && styles.displayContent}`}>
                    <RichText content={content} />
                </div>
            }


        </div>
    )
}

export default IngredientsDescription