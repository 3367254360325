// extracted by mini-css-extract-plugin
export var categoryTitle = "product-form-module--category-title--7073f";
export var col_1 = "product-form-module--col_1--d9f85";
export var col_2 = "product-form-module--col_2--05c68";
export var container = "product-form-module--container--5db4e";
export var description = "product-form-module--description--5fb56";
export var dropbtn = "product-form-module--dropbtn--1b4a9";
export var dropup = "product-form-module--dropup--ade7d";
export var dropupContent = "product-form-module--dropup-content--bf40f";
export var h2 = "product-form-module--h2--db1d3";
export var package_size = "product-form-module--package_size--80cc0";
export var product_img = "product-form-module--product_img--e8902";
export var psDisabled = "product-form-module--ps-disabled--d7384";
export var psWidget = "product-form-module--ps-widget--d8b80";
export var shop = "product-form-module--shop--56b92";
export var space = "product-form-module--space--6296f";
export var subTitle = "product-form-module--sub-title--3f479";