import React, { useEffect,useState } from "react";
import ProductForm from "../ProductForm/ProductForm";
import Img from "gatsby-image";
import * as styles from './product-hero.module.scss';
import { useDictionaryQuery } from "../../../hooks";
import { navigate } from 'gatsby'
//import * as styles from './product-form.module.scss';
import ReactImageMagnify from '@milosmladenovicwork/react-image-magnify';
import { useWindowSize } from 'react-use'
import ImageGridContainer from "../ImageGridContainer/ImageGridContainer";

const ProductHero = ({
    heroImage,
    categoryTitle,
    title,
    subTitle,
    longSubtitle,
    packageSize,
    productId,
    cta,
    relatedProducts,
    productDescription,
    ingredientsDescription,
    imageGrid

}) => {
    const { width: windowWidth } = useWindowSize()
    const { backButton } = useDictionaryQuery()
    useEffect(() => {
        document.documentElement.style.setProperty("--swiper-navigation-color", "#000")
        document.documentElement.style.setProperty("--swiper-pagination-color", "#000")
    }, [])

    const productImages = imageGrid ? imageGrid[0]?.productImages : undefined
    const [selectImage,setSelectImage] = useState(0);
    return (
        <div>
            <div className={styles.container}>
                <div className={styles.col_1}>
                    <div onClick={() => { navigate(-1) }} className={`${styles.backButton} ${styles.backButtonDiv}`}>
                        <span className={`styles.button`}>{backButton}</span>
                    </div>
                    <div>
                        {
                            windowWidth > 1350 ?
                                <div className={styles.heroImage}>
                                    <ReactImageMagnify {...{
                                        smallImage: {
                                            alt: title? title : null,
                                            isFluidWidth: true,
                                            src: (productImages ? productImages[selectImage] : heroImage)?.fluid?.src
                                        },
                                        largeImage: {
                                            src: (productImages ? productImages[selectImage] : heroImage)?.fluid?.src,
                                            width: 1000,
                                            height: 1200
                                        },
                                    }} />
                                </div>
                                :
                                windowWidth > 1024 &&
                                <div className={styles.heroImage}>
                                    <ReactImageMagnify {...{
                                        smallImage: {
                                            alt: title? title : null,
                                            isFluidWidth: true,
                                            src: (productImages ? productImages[selectImage] : heroImage)?.fluid?.src
                                        },
                                        largeImage: {
                                            src: (productImages ? productImages[selectImage] : heroImage)?.fluid?.src,
                                            width: 700,
                                            height: 800
                                        },
                                    }} />
                                </div>
                        }
                    </div>
                    <div>
                        <div className={styles.productImage}>
                            <Img fluid={(productImages ? productImages[selectImage] : heroImage)?.fluid} alt={heroImage.title} loading="lazy" fadeIn={false} />
                        </div>
                    </div>
                    {productImages && <ImageGridContainer productImages={productImages} setSelectImageFunc={setSelectImage}/>}
                </div>
                <ProductForm
                    categoryTitle={categoryTitle}
                    title={title}
                    subTitle={subTitle}
                    longSubtitle={longSubtitle}
                    packageSize={packageSize}
                    productId={productId}
                    cta={cta}
                    relatedProducts={relatedProducts}
                    productDescription={productDescription}
                    ingredientsDescription={ingredientsDescription} slug={undefined}                />

            </div>
        </div>
    )
}

export default ProductHero