import React, { useState } from "react"

import * as styles from "./reviews-description.module.scss"

const ProductReviews = ({
    title,
    productId,
}: {
    title: string
    productId: number
}) => {
    const [display, setDisplay] = useState(true)
    return (
        <div>
            <div
                className={`${styles.productDiscription} ${display && styles.productIcon
                    }`}
            >
                <div
                    className={`${styles.descriptionTitle}`}
                    onClick={() => {
                        setDisplay(!display)
                    }}
                >
                    <a>{title}</a>
                    <p className={`${`styles.productPlus`}`}></p>
                </div>
            </div>
            <div className={`${styles.content} ${display && styles.displayContent}`}>
                <div data-bv-show="reviews" data-bv-product-id={productId}></div>
            </div>
        </div>
    )
}

export default ProductReviews