import React, { useEffect, useRef } from "react"
import Img from "gatsby-image"
import "swiper/css/navigation"
import "swiper/css/pagination"
import * as styles from "./ImageGridContainer.module.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper"
import { useLocale } from "../../../hooks"

const ImageGridContainer = ({
  productImages,
  setSelectImageFunc,
  navigation,
}) => {
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--swiper-navigation-size-scientist",
      "2rem"
    )
  }, [])
  const swiperRef = useRef(null)
  useEffect(() => {
    const timer = setTimeout(() => {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.update()
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }

  const option = {
    spaceBetween: 20,
    loop: productImages.length > 2 ? true : false,
    slidesPerGroupSkip: 1,
    grabCursor: true,
    keyboard: {
      enabled: true,
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
        navigation: false,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
        spaceBetween: 5,
        navigation: false,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
    },
    modules: [Pagination, Navigation],
    slidesPerView: 3,
  }

  const locale = useLocale()
  return (
    <div>
      {locale === "en-US" ? (
        <div className={styles.imgContainer}>
          <div
            className={`${styles.arrowContainer} ${
              navigation &&
              productImages.length <= 3 &&
              styles.displayNavigation
            }`}
          >
            <div
              className={`${styles.prevButton}`}
              id="prev-button"
              onClick={() => {
                goPrev()
              }}
            ></div>
          </div>
          <Swiper {...option} ref={swiperRef}>
            {productImages.map((productImage, index) => {
              return (
                <SwiperSlide key={index}>
                  <div key={index} onClick={() => setSelectImageFunc(index)}>
                    <Img
                      className={styles.prodImg}
                      fluid={productImage.fluid}
                      alt={productImage.title}
                    />
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
          <div
            className={`${styles.arrowContainer} ${
              styles.arrowContainerRight
            } ${
              navigation &&
              productImages.length <= 3 &&
              styles.displayNavigation
            } `}
          >
            <div className={`${styles.nextButton}`} onClick={goNext}></div>
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.imgContainer}>
            {productImages.map((productImage, index) => {
              return (
                  <div key={index} onClick={() => setSelectImageFunc(index)}>
                    <Img
                      className={styles.prodImg}
                      fluid={productImage.fluid}
                      alt={productImage.title}
                    />
                  </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default ImageGridContainer
