import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'

const SchemaUS = ({
    schema = []
}) => {

        return (
            <Helmet>

                {schema && (
                        <script type="application/ld+json">{JSON.stringify(schema)}</script>
                    )}

            </Helmet>
        )
}

export default SchemaUS