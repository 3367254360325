// extracted by mini-css-extract-plugin
export var arrowContainer = "article-related-articles-module--arrow-container--ac8cf";
export var arrowContainerRight = "article-related-articles-module--arrow-container-right--bbfad";
export var articleCards = "article-related-articles-module--article-cards--27066";
export var article_container = "article-related-articles-module--article_container--37614";
export var bar = "article-related-articles-module--bar--50f7a";
export var displayNavigation = "article-related-articles-module--display-navigation--49d6d";
export var featuredArticleTitle = "article-related-articles-module--featured-article-title--17954";
export var icon = "article-related-articles-module--icon--d5d10";
export var img_wrapper = "article-related-articles-module--img_wrapper--adbc9";
export var mainImg = "article-related-articles-module--mainImg--51a01";
export var nextButton = "article-related-articles-module--next-button--3cb73";
export var prevButton = "article-related-articles-module--prev-button--cdaf8";
export var swiperContainer = "article-related-articles-module--swiper-container--d70bf";
export var triangle_wrapper = "article-related-articles-module--triangle_wrapper--ba112";
export var triangle_wrapper_left = "article-related-articles-module--triangle_wrapper_left--621e9";
export var triangle_wrapper_right = "article-related-articles-module--triangle_wrapper_right--51358";