import React, { useEffect, useRef } from "react";

import * as styles from './article-related-articles.module.scss';
import './related-articles-slick.scss';
import ProductCard from "../../components/Shared/ProductCard/ProductCard";
// import Slider from "react-slick"
import { useSiteQuery, useDictionaryQuery } from "../../hooks";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Pagination } from "swiper";
import { useWindowSize } from "react-use"

const ArticleRelatedArticles = ({
    articles,
    articlesIcon,
    align = true
}: {
    articles: any,
    articlesIcon: any,
    align: boolean
}) => {

    const { articlesGlobalIcon } = useSiteQuery()
    const { discoverAWorld } = useDictionaryQuery();
    const { width: windowWidth } = useWindowSize()
    useEffect(() => {
        document.documentElement.style.setProperty("--swiper-navigation-size-scientist", "2rem")
    }, [])
    const swiperRef = useRef(null)
    useEffect(() => {
        const timer = setTimeout(() => {
            if (swiperRef.current && swiperRef.current.swiper) {
                swiperRef.current.swiper.update();
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [])

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    }

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    }
    
    const option = {
        spaceBetween: 20,
        loop: articles?.length > 2 ? true : false,
        centeredSlides: align,
        slidesPerGroupSkip: 1,
        grabCursor: true,
        keyboard: {
            enabled: true
        },
        pagination: {
            dynamicBullets: true,
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
                navigation: false
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1.5,
                spaceBetween: 10,
                navigation: false
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 30
            }
        },
        modules: [Pagination, Navigation],
        slidesPerView: 3,
    }
    return (
        // {title && (
        //     <>
        //         <h3>{title}</h3>
        //     </>
        // )}
        <div className={styles.article_container}>
         {  discoverAWorld && <h2 className="yellow_border">
                {discoverAWorld}
            </h2> }
            <div >
                <div className={`${styles.swiperContainer}`}>
                {windowWidth < 1024 ?
                    
                    <div className={`${styles.arrowContainer}`}>
                        <div
                            className={`${styles.prevButton}`}
                            id="prev-button"
                            onClick={() => {
                                goPrev()
                            }}
                        ></div>
                    </div>  : windowWidth > 1023 && articles?.length > 3 ?
                        <div className={`${styles.arrowContainer}`}>
                        <div
                            className={`${styles.prevButton}`}
                            id="prev-button"
                            onClick={() => {
                                goPrev()
                            }}
                        ></div>
                    </div> : null
                    }
                    <Swiper
                        {...option}
                        ref={swiperRef}
                    >
                        {
                            articles?.map(({ title, slug, thumbnailImage, thumbnailCategoryTitle, articlePageIcon, categoryTitle }, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div>
                                            <div className={`${styles.img_wrapper} ${styles.articleCards}`}>
                                                <ProductCard
                                                    fluid={articlesIcon ? articlesIcon.fluid : articlePageIcon ? articlePageIcon.fluid : articlesGlobalIcon?.fluid}
                                                    desc={title}
                                                    icon={articlesIcon && articlesIcon.fluid && articlesIcon.title}
                                                    title={thumbnailCategoryTitle ? thumbnailCategoryTitle : null}
                                                    bgImg={thumbnailImage[0]?.fluid}
                                                    bgImgTitle={thumbnailImage ? thumbnailImage[0]?.title : title}
                                                    to={slug}
                                                />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                    {windowWidth < 1024 ?
                    <div className={`${styles.arrowContainer} ${styles.arrowContainerRight} `}>
                        <div
                            className={`${styles.nextButton}`}
                            onClick={goNext}
                        ></div>
                    </div> 
                    : windowWidth > 1023 && articles?.length > 3 ?
                    <div className={`${styles.arrowContainer} ${styles.arrowContainerRight} `}>
                        <div
                            className={`${styles.nextButton}`}
                            onClick={goNext}
                        ></div>
                    </div>
                     : null
                     }
                </div>
            </div>
            {/* {title && (
                <>
                    <h3>{title}</h3>
                </>
            )}
            <div className={styles.img_wrapper}>
                <ProductCard
                    fluid={iconFluid}
                    title={imgTitle}
                    desc={description}
                    bgImg={bgFluid}
                    to={link}
                />
            </div> */}
            <div
                className={`${true
                    ? styles.triangle_wrapper_left
                    : styles.triangle_wrapper_right
                    }`}
            >
                <div className="triangle"></div>
            </div>
        </div>
    )
}

export default ArticleRelatedArticles