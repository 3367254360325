import React from "react"
import Img from "gatsby-image"
import * as styles from "./ProductCard.module.scss"
import { Link } from "gatsby"
import { useLocalPath ,useLocale} from "../../../hooks"



const ProductCard = ({ fluid, icon, title, desc,thumbnailCategoryTitle,thumbnailTitle, bgImg, to, bgImgTitle }) => {
const locale = useLocale()   
    if (to) {
        return (
            <Link to={useLocalPath(`/${to}`)}>
                <div className={styles.img_wrapper}>
                    {
                        icon ? <Img fluid={icon.fluid} alt={icon.title} className={styles.icon}></Img>
                            : fluid && <Img fluid={fluid} alt="Article Icon" className={styles.icon}></Img>
                    }
                    {/* {fluid && <Img fluid={fluid} className={styles.icon}></Img>} */}
                    <p>{title} </p>
                    <p>{thumbnailCategoryTitle}</p>
                     { thumbnailTitle ?
                    <h2>{thumbnailTitle}</h2>
                    : desc && <h2>{desc} </h2>
                     }
                    <Img fluid={bgImg} alt={bgImgTitle? bgImgTitle:desc} className={styles.mainImg}></Img>
                    <div className={styles.triangle_wrapper}>
                        <div className="triangle"></div>
                    </div>
                </div>
            </Link>
        )
    } else {
        return (
            <div className={styles.img_wrapper}>
                {
                    icon ? <Img fluid={icon.fluid} alt={icon.title} className={styles.icon}></Img>
                        : fluid && <Img fluid={fluid} alt="Article Icon" className={styles.icon}></Img>
                }
                <p>{title}</p>
                <p>{thumbnailCategoryTitle}</p>
                <h3>{desc}</h3>
                <h3>{thumbnailTitle}</h3>

                <Img fluid={bgImg} alt={bgImgTitle ? bgImgTitle : desc} className={styles.mainImg}></Img>
                <div className={styles.triangle_wrapper}>
                    <div className="triangle"></div>
                </div>
            </div>
        )
    }
}

export default ProductCard
